<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <Costs
      :project_id="'0'"
      :select_project="true"
    />

  </div>
</template>

<script>
import Costs from '@/components/Projects/Select/Costs.vue';
export default {
  components: {
    Costs
  },
  data: () => ({
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista kosztów',
        disabled: true,
        to: '/koszty',
      },
    ],
  }),
  methods: {

  },
  mounted(){

  }
};
</script>

<style lang="scss" scoped>
</style>